const UNFOLDED_ITEM_CLASS_NAME = 'accordion-item_unfolded';
const ITEM_CONTENT_CLASS_NAME = 'accordion-item__content';

const sectionEl = document.querySelector('.how-to-start');
const accordionEl = sectionEl.querySelector('.accordion');
const sliderHSymbolEl = sectionEl.querySelector('.symbol_horizontal');
const sliderVSymbolEl = sectionEl.querySelector('.symbol_vertical');
const accordionItemEls = accordionEl.querySelectorAll('.accordion-item');

let accordionItemUnfoldedEl = [...accordionItemEls].find((el) => {
    return el.classList.contains(UNFOLDED_ITEM_CLASS_NAME);
});
let accordionItemUnfoldedContentEl = accordionItemUnfoldedEl.querySelector(`.${ITEM_CONTENT_CLASS_NAME}`);

const toggleAccordionItem = (accordionItemEl) => {
    accordionItemUnfoldedEl.classList.remove(UNFOLDED_ITEM_CLASS_NAME);
    accordionItemEl.classList.add(UNFOLDED_ITEM_CLASS_NAME);
};

const switchDisplayingContent = (accordionItemEl) => {
    const unfoldedItemContentVisibleClassName = 'accordion-item__content_visible';

    accordionItemUnfoldedContentEl.classList.remove(unfoldedItemContentVisibleClassName);
    accordionItemUnfoldedContentEl = accordionItemEl.querySelector(`.${ITEM_CONTENT_CLASS_NAME}`);
    accordionItemUnfoldedContentEl.classList.add(unfoldedItemContentVisibleClassName);
};

const interactSlider = (accordionItemEl) => {
    const accordionItemElBCR = accordionItemEl.getBoundingClientRect();
    const accordionCollapsedItemSize = [...accordionItemEls].reduce(
        (agg, el) => {
            const elBCR = el.getBoundingClientRect();

            return {
                width: Math.min(agg.width, elBCR.width),
                height: Math.min(agg.height, elBCR.height),
            };
        },
        { width: accordionItemElBCR.width, height: accordionItemElBCR.height }
    );
    const elIndex = [...accordionItemEls].indexOf(accordionItemEl);
    const symbolH = sliderHSymbolEl;
    const symbolV = sliderVSymbolEl;

    const maxIndex = accordionItemEls.length - 1;

    if (!symbolH.classList.contains('symbol_next') && elIndex > 1) {
        symbolH.classList.toggle('symbol_next');
        symbolV.classList.toggle('symbol_next');
    }

    if (symbolH.classList.contains('symbol_next') && (elIndex <= 1 || elIndex > 3)) {
        symbolH.classList.toggle('symbol_next');
        symbolV.classList.toggle('symbol_next');
    }

    symbolH.style.left = `${accordionCollapsedItemSize.width * (elIndex < maxIndex ? elIndex : maxIndex)}px`;
    symbolV.style.top = `${accordionCollapsedItemSize.height * (elIndex < maxIndex ? elIndex : maxIndex)}px`;
};

accordionEl.addEventListener('mousemove', (evt) => {
    const accordionItem = evt.target.closest('.accordion-item');

    if (!accordionItem || accordionItem === accordionItemUnfoldedEl) {
        return;
    }

    toggleAccordionItem(accordionItem);
    switchDisplayingContent(accordionItem);
    interactSlider(accordionItem);

    accordionItemUnfoldedEl = accordionItem;
});
