const root = document.querySelector('.scroll-container');
const sections = document.querySelectorAll('section');
const scrollNavEl = document.querySelector('.scroll-nav__items');
const SectionsToNavItems = new Map();
let activeNavItem;

const switchActiveNavItem = (intersectingSection) => {
    const activeNavItemClassName = 'scroll-nav-item__active';
    activeNavItem.classList.remove(activeNavItemClassName);
    activeNavItem = SectionsToNavItems.get(intersectingSection);
    activeNavItem.classList.add(activeNavItemClassName);
};

const intersectionObserver = new IntersectionObserver(
    (entries) => {
        const intersectingEntry = entries.find((e) => e.isIntersecting);

        if (!intersectingEntry) {
            return;
        }

        switchActiveNavItem(intersectingEntry.target);
    },
    { root, rootMargin: '0px', threshold: 0.5 }
);

const createNavItemEl = () => {
    const el = document.createElement('div');

    el.classList.add('scroll-nav__item', 'scroll-nav-item');

    return el;
};

const initScrollNavItems = () => {
    for (let s of sections) {
        const el = createNavItemEl();

        if (!activeNavItem) {
            activeNavItem = el;
            el.classList.add('scroll-nav-item__active');
        }

        scrollNavEl.appendChild(el);

        SectionsToNavItems.set(s, el);

        el.addEventListener('click', () => {
            s.scrollIntoView({ behavior: 'smooth' });
        });

        intersectionObserver.observe(s);
    }
};

initScrollNavItems();
