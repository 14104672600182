const root = document.querySelector('.scroll-container');
const aboutSection = document.querySelector('.about');
const mainButtonHeaderEl = document.querySelector('.main-action');

const observer = new IntersectionObserver(
    (entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
            mainButtonHeaderEl.classList.add('main-action_hidden');
            return;
        }

        mainButtonHeaderEl.classList.remove('main-action_hidden');
    },
    { root, threshold: 0.1 }
);

observer.observe(aboutSection);
